import React, { useContext } from 'react';
import { Link } from 'gatsby';
import {
	GlobalStateContext,
	GlobalDispatchContext,
} from '../context/globalContextProvider';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from './cart.module.css';

function CartPage() {
	const state = useContext(GlobalStateContext);
	const dispatch = useContext(GlobalDispatchContext);

	const handleRemoveClick = e => {
		const path = e.target.dataset.itemPath;
		dispatch({ type: 'DELETE_CART_ITEM', path });
	};

	const handleQuantityChange = e => {
		const path = e.target.dataset.itemPath;
		const amount = e.target.value;
		dispatch({ type: 'UPDATE_CART_ITEM', path, amount });
	};

	const quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	const items = state.items;

	return (
		<Layout>
			<SEO title="Cart" />
			<section className={styles.section}>
				<h2 className={styles.title}>ตะกร้าของคุณ</h2>
				{items.map(item => {
					const { thumbnail, title, size, price, amount, path } = item;
					const total = price * amount;
					return (
						<div className={styles.itemWrapper} key={path}>
							<div className={styles.thumbnailWrapper}>
								<Link to={path}>
									<img
										className={styles.thumbnail}
										src={thumbnail}
										alt={title}
									/>
								</Link>
							</div>
							<div>
								<h5 className={styles.itemTitel}>{title}</h5>

								<div className={styles.details}>ขนาดสินค้า {size}ml</div>
								<div className={styles.details}>
									<span>จำนวน </span>
									<select
										name="amount"
										value={amount}
										data-item-path={path}
										onChange={handleQuantityChange}
									>
										{quantities.map(quantity => (
											<option value={quantity} key={quantity}>
												{quantity}
											</option>
										))}
									</select>
								</div>

								<button
									className={styles.removeButton}
									onClick={handleRemoveClick}
									data-item-path={path}
								>
									ลบสินค้า
								</button>
								<div className={styles.price}>฿ {total}</div>
							</div>
						</div>
					);
				})}
				<Link className={styles.button} to="/checkout">
					เช็คเอาท์
				</Link>
			</section>
		</Layout>
	);
}

export default CartPage;
